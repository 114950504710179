/* eslint-disable no-undef, react/prop-types */
import React from 'react'
// import Link from 'gatsby-link'

import {
  Page,
  H1,
  Hr,
  // GatsbyLink,
  Box,
  Flex,
  FeatherIcon,
  Text,
  Paper,
} from '../elements'
import { theme } from '../utils'
import { SEO, HeadingStaticPage } from '../components'
// import { breakLongStrings, addInlineLinks } from '../utils'

const appStoreText = {
  fr: `<strong>À présent, pour les appareils Android l'app est aussi disponible <a
      href="https://play.google.com/store/apps/details?id=xyz.appmaker.oanryc&hl=fr"
      target="_blank"
      rel="noopener noreferrer"
    >
      sur le Play Store
    </a></strong>`,
  en: `<strong>The app is now <a
      href="https://play.google.com/store/apps/details?id=xyz.appmaker.oanryc&hl=en"
      target="_blank"
      rel="noopener noreferrer"
    >
      available on the Play Store
    </a> for Android users</strong>`,
  nl: `<strong>Nu is de app voor Android-apparaten ook <a
      href="https://play.google.com/store/apps/details?id=xyz.appmaker.oanryc&hl=nl"
      target="_blank"
      rel="noopener noreferrer"
    >
      beschikbaar in de Play Store
    </a></strong>`,
}

const text = {
  fr: `<p>Cette application est &agrave; la fois un site web et une App. On appelle cela une "Progressive Web App" (PWA). Cela vous permet notamment de sauvegarder le contenu du site pour le consulter hors ligne et d'installer notre PWA sur votre smartphone pour qu'elle apparaisse parmi vos applications mobiles classiques.</p>
<p>La meilleure compatibilit&eacute; est obtenue sur les syst&egrave;mes Android r&eacute;cents en utilisant le navigateur Chrome.<br />IOS supporte (de mani&egrave;re moins &eacute;l&eacute;gante) les PWAs depuis la version 11.3 et avec Safari.</p>
<h2>Installer sur Android + Chrome</h2>
<p>En ouvrant le site, vous devriez voir une boite de dialogue vous invitant &agrave; "ajouter BGF &agrave; l'&eacute;cran d'accueil". Si la boite de dialogue n'apparait pas, vous pouvez s&eacute;lectionner manuellement l'option dans les param&egrave;tres Chrome.</p>
<h2>Installer sur IOS + Safari</h2>
<p>Naviguez vers le site, cliquez sur l'icone "Partager" (carr&eacute; avec fl&ecirc;che) et ensuite "Sur l'&eacute;cran d'accueil".</p>
<p>Il est malheureusement possible que l'icone de l'app n'apparaisse pas telle qu'elle le devrait. Vous aurez dans ce cas une image de la page d'accueil en lieu et place de l'icone.</p>
<h2>Consulter hors ligne</h2>
<p>Vous n'&ecirc;tes pas oblig&eacute; d'installer l'application pour b&eacute;n&eacute;ficier des fonctionnalit&eacute;s hors ligne mais cela facilite le processus.</p>
<p>Pour sauvegarder le contenu hors ligne, naviguez simplement sur les pages qui vous int&eacute;ressent. Tout le contenu que vous verrez sera automatiquement t&eacute;l&eacute;charg&eacute; pour une consultation ult&eacute;rieure.</p>
<h2>Autres remarques</h2>
<p>Si vous &ecirc;tes connect&eacute; au r&eacute;seau, il est possible que le site se rafraichisse automatiquement en naviguant vers celui-ci. Cela signifie simplement qu'une mise &agrave; jour a &eacute;t&eacute; mise en ligne et les nouvelles donn&eacute;es sont donc t&eacute;l&eacute;charg&eacute;es.</p>`,
  en: `
  <p>This application is both a website and an App. It is called a "Progressive Web App" (PWA). It allows you to save the content for offline use and you can even install the App on your smartphone and make it look like a classic mobile app.</p>
<p>You will have the best compatibility when using an Android phone with Chrome.<br />IOS supports (less elegantly though) PWAs since version 11.3 using Safari.</p>
<h2>How to install on Android + Chrome</h2>
<p>When navigating to the website you should see a dialog box inviting you to "Add BGF to Home Screen".<br />If you don't see it you can select the option manually in your Chrome settings.</p>
<h2>How to install on IOS + Safari</h2>
<p>When on the website's homepage, clic on the "Share" icon (the square and arrow) and then "on Home Screen".</p>
<p>Unfortunately it is possible the icon does not show up as it should. You may instead see a preview image of the homepage.</p>
<h2>Offline browsing</h2>
<p>You don't have to install the App on your Home Screen to enjoy offline browsing. It just eases the process of accessing the App.</p>
<p>To save the content offline, simply navigate on the pages you are interested in. All the content you see when you are online will be saved on your device.</p>
<h2>More considerations</h2>
<p>If you are online you may see the App refreshing by itself. It just means there is a new version online and the new data just got downloaded.</p>
  `,
  nl: `
<p>Deze toepassing is tegerlijkertijd een website en een App. Dit wordt "Progressive Web App" (PWA) genoemd. Het laat u toe om de inhoud van de site te behouden om het off-line te raadplegen, en ook om onze PWA op uw smartphone te installeren, zodat die tussen uw classieke mobile applicaties verschijnt.</p>
<p>De beste match vindt u op recente Android systemen, via Chrome browser.<br />IOS verdraagt (iets minder) de PWA&rsquo;s sinds versie 11.3 en met Safari.</p>
<h2>Installatie op Android + Chrome</h2>
<p>Bij opening van de site, moet u een dialoogvenster vinden, dat u uitnodigd met "Voeg BGF aan het onthaalscherm toe". Indien de dialoogvenster niet verschijnt, kunt u de optie manueel in de Chrome parameters selecteren.</p>
<h2>Installatie op IOS + Safari</h2>
<p>Surf naar de site, clik op icoon "Delen" (vierkant met pijl), daarna op "onthaalscherm".</p>
<p>Het kan spijtig genoeg dat de applicatie icoon niet verschijnt zoals het zou moeten. In dat geval krijgt u een beeld van de onthaalpagina in plaats van de icoon.</p>
<h2>Raadpleging off line</h2>
<p>Om van de off line functionaliteiten te kunnen genieten bent u niet verplicht om de applicatie te installeren, maar het vergemakkelijkt de process.</p>
<p>Om de off line content te behouden, surf gewoon op de bladzijden waarin u geinteresserd bent. Al het inhoud dat u ziet wordt automatisch geladen, voor latere raadpleging.</p>
<h2>Andere opmerkingen</h2>
<p>Als u op het netwerk bent is het mogelijk dat een refresh automatisch gebeurt bij het surfen. Het wilt gewoon zeggen dat een update beschikbaar is, en de nieuwe gegevens worden dus opgeladen.</p>
  `,
}

const AboutTheAppPage = () => (
  <Page>
    {({ t, currentLocale }) => (
      // const str = breakLongStrings({ string: t('gamesMarketText'), tag: 'p' })
      // const strWithLinks = addInlineLinks(str)

      <React.Fragment>
        <SEO
          {...{
            url: `/about-the-app/`,
            title: t('aboutTheApp'),
            // description: true,
          }}
        />
        <HeadingStaticPage
          {...{
            icon: 'help-circle',
            bg: 'lightGreen',
            color: 'textAlt',
            stroke: 'textAlt',
            title: t('aboutTheApp'),
          }}
        />

        <Box.container {...{ py: 0 }}>
          <Paper
            {...{
              maxWidth: '90%',
              m: 'auto',
              mt: 3,
              width: 'fit-content',
            }}
          >
            <Text
              {...{
                as: 'p',
                color: 'text',
                className: 'break show-links',

                dangerouslySetInnerHTML: {
                  __html: appStoreText[currentLocale],
                },
              }}
            />
          </Paper>
          <Text
            {...{
              as: 'p',
              color: 'text',
              className: 'break show-links',

              css: {
                '& h2': {
                  fontSize: theme.fontSizes[3],
                },
              },

              dangerouslySetInnerHTML: { __html: text[currentLocale] },
            }}
          />
        </Box.container>
      </React.Fragment>
    )}
  </Page>
)

export default AboutTheAppPage
